

export { Locked };

function Locked() {
    return (
        <div className="col-md-6 offset-md-3 mt-5">
            <div className="alert alert-info">
               LOCKED!!!
            </div>

        </div>
    )
}
